<template>
  <div>
    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="detailItem()"
          v-if="btnDetail"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-information </v-icon>
        </v-btn>
      </template>
      <span>Detalles</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="checkItem()"
          v-if="btnCheck"
          v-bind="attrs"
          v-on="on"
          :loading="loaderCheck[index]"
        >
          <v-icon> mdi-clipboard-arrow-right </v-icon>
        </v-btn>
      </template>
      <span>{{ textCheck }}</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="editItem()" v-if="btnEdit" v-bind="attrs" v-on="on">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="recoveryItem()"
          v-if="btnRecovery"
          v-bind="attrs"
          v-on="on"
          :loading="loaderRecovery[index]"
        >
          <v-icon> mdi-lock-open </v-icon>
        </v-btn>
      </template>
      <span>Recuperar contraseña</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="deleteItem()"
          v-if="btnDelete"
          v-bind="attrs"
          v-on="on"
          :loading="loaderDelete[index]"
        >
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
      <span>Eliminar</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="downPdf()"
          v-if="btnDownPdf"
          v-bind="attrs"
          v-on="on"
          :color="colorBtnDownPdf"
          :loading="loaderDownPdf"
        >
          <v-icon> mdi-tray-arrow-down </v-icon>
        </v-btn>
      </template>
      <span>Descargar</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="apply()"
          v-if="btnApply"
          v-bind="attrs"
          v-on="on"
          :color="colorBtnApply"
          :loading="loaderApply"
        >
          <v-icon> mdi-check </v-icon>
        </v-btn>
      </template>
      <span>Aceptar</span>
    </v-tooltip>

    <v-tooltip bottom color="dark">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          :color="colorBtnCancel"
          @click="cancel()"
          v-if="btnCancel"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
      <span>Cancelar</span>
    </v-tooltip>

    <v-btn icon :color="colorBtnBack" @click="back()" v-if="btnBack">
      <v-icon> mdi-arrow-left </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CustomButtonAction",
  props: {
    value: Object,
    loaderApply: Boolean,
    loaderDownPdf: Boolean,
    loaderRecovery: Object,
    loaderDelete: Object,
    loaderCheck: Object,
    index: [String, Number],
    btnDetail: {
      type: Boolean,
      default: false,
    },
    btnEdit: {
      type: Boolean,
      default: false,
    },
    btnRecovery: {
      type: Boolean,
      default: false,
    },
    btnDelete: {
      type: Boolean,
      default: false,
    },
    btnApply: {
      type: Boolean,
      default: false,
    },
    btnCancel: {
      type: Boolean,
      default: false,
    },
    btnBack: {
      type: Boolean,
      default: false,
    },
    btnCheck: {
      type: Boolean,
      default: false,
    },
    btnDownPdf: {
      type: Boolean,
      default: false,
    },
    colorBtnCancel: {
      type: String,
      default: "second",
    },
    colorBtnBack: {
      type: String,
      default: "second",
    },
    colorBtnApply: {
      type: String,
      default: "second",
    },
    colorBtnDownPdf: {
      type: String,
      default: "second",
    },
    textCheck: {
      type: String,
      default: "",
    },
  },
  methods: {
    detailItem() {
      if (this.btnDetail) {
        this.$emit("onChangeDetail", this.value);
      }
    },
    editItem() {
      if (this.btnEdit) {
        this.$emit("onChangeEdit", this.value);
      }
    },
    recoveryItem() {
      if (this.btnRecovery) {
        this.$emit("onChangeRecovery", this.value);
      }
    },
    deleteItem() {
      if (this.btnDelete) {
        this.$emit("onChangeDelete", this.value);
      }
    },
    apply() {
      this.$emit("onChangeApply", true);
    },
    cancel() {
      this.$emit("onChangeCancel", true);
    },
    back() {
      this.$emit("onChangeBack", true);
    },
    checkItem() {
      if (this.btnCheck) {
        this.$emit("onChangeCheck", this.value);
      }
    },
    downPdf() {
      if (this.btnDownPdf) {
        this.$emit("onChangeDownPdf", true);
      }
    },
  },
};
</script>
