import axios from "axios";

const NotificationServices = () => {
  const notificatioSendGlobal = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/notification/send`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const notificatioAll = async (typeuser) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/notification/${typeuser}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const notificatioMobileAll = async (userConnect) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/notification/mobile/${userConnect}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const notificatioCount = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/notification/count`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const notificatioMobileCount = async (userConnect) => {
    return await axios
      .get(
        `${process.env.VUE_APP_ROOT_API}/notification/mobile/count/${userConnect}`
      )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const notificatioCheck = async (id) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/notification/check/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const notificatioMobileCheck = async (id) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/notification/mobile/check/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    notificatioSendGlobal,
    notificatioCount,
    notificatioAll,
    notificatioCheck,
    notificatioMobileCheck,
    notificatioMobileAll,
    notificatioMobileCount,
  };
};

export default NotificationServices;
