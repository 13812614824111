<template>
  <div class="noty-admin page-list">
    <v-row>
      <v-col cols="12" md="7" class="btn-send">
        <v-dialog
          v-model="dialog"
          max-width="500px"
          persistent
          scrollable
          content-class="admin-dialog dialog-notify"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              color="second"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              Enviar notificaciones
              <v-icon style="margin-left: 10px">mdi-send</v-icon>
            </v-btn>
          </template>

          <v-card class="send-message">
            <v-card-title class="card-title">
              <span class="headline white--text">Enviar notificación</span>

              <v-spacer></v-spacer>

              <CustomButtonAction
                :btnCancel="true"
                colorBtnCancel="white"
                @onChangeCancel="cancel()"
              />
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col md="12">
                    <v-text-field
                      outlined
                      label="Título de la notificación"
                      v-model="title"
                      color="second"
                      :rules="requiredRules"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-textarea
                      outlined
                      label="Mensaje"
                      v-model="message"
                      color="second"
                      :rules="requiredRules"
                      rows="4"
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-file-input
                      label="Seleccionar imagen"
                      v-model="file"
                      outlined
                      dense
                      accept="image/*"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon=""
                      color="second"
                      @change="processWebImage()"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="btn-send">
                <v-btn
                  color="second"
                  class="white--text"
                  @click="btnSend()"
                  :loading="btnLoader"
                >
                  Enviar <v-icon>mdi-send</v-icon>
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row v-if="notify.length === 0">
      <v-col cols="12">
        <div class="message-error">No tiene notificaciones registradas</div>
      </v-col>
    </v-row>

    <v-row v-if="notify.length > 0">
      <v-col cols="12" md="7">
        <v-timeline dense>
          <v-timeline-item v-for="(item, i) in notify" :key="i" color="second">
            <v-card
              class="elevation-3"
              @click="viewNotify(item, i)"
              :loading="checkLoader[i]"
            >
              <v-list-item>
                <v-list-item-content>
                  <!-- <span class="card-subtitle">
                    {{ item.createdAt | dateTime }}
                  </span> -->

                  <v-list-item-title class="text-h6 second--text card-title">
                    {{ item.title }}
                    <span class="card-subtitle">
                      {{ item.createdAt | dateTime }}
                    </span>
                  </v-list-item-title>

                  <v-list-item-subtitle class="card-message">
                    {{ item.message }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    v-if="item.check === 1 && item.status !== 0"
                    :loading="checkLoader[i]"
                    class="icon-warning"
                  >
                    <v-icon style="font-size: 30px">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                  <v-btn icon v-if="item.check === 0 && item.status !== 0">
                    <v-icon style="font-size: 30px">
                      mdi-chevron-right-circle-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import useValidate from "../../../../shared/validate/useValidate";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import NotificationServices from "../../../../core/services/notification-services";
import NotifyStorage from "../../../../core/storage/notification-storage";

export default {
  name: "NotificationAdmin",
  data: () => ({
    title: "",
    message: "",
    file: undefined,
    image: undefined,
    valid: true,
    btnLoader: false,
    checkLoader: {},
    requiredRules: [useValidate["required"]],
    dialog: false,
  }),
  computed: {
    ...mapState(["notify"]),
  },
  components: {
    CustomButtonAction,
  },
  created() {
    this.getAllNotify();
  },
  methods: {
    ...mapActions(["getNotify", "getCountNotify"]),
    btnSend() {
      const { notificatioSendGlobal } = NotificationServices();
      const validate = this.$refs.form.validate();
      if (validate) {
        this.btnLoader = true;
        const data = {
          title: this.title,
          message: this.message,
          image: this.image,
        };

        notificatioSendGlobal(data).then((resp) => {
          const response = resp.data;
          if (response.code !== 202) {
            console.log("Error al enviar la notificacion", response);
          } else {
            this.$toast.success(
              "La notificación se envío de forma exitosa a todos los vendoderes"
            );
          }
          this.title = "";
          this.message = "";
          this.file = undefined;
          this.image = undefined;
          this.btnLoader = false;
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        });
      }
    },
    processWebImage() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = async (readerEvent) => {
          const image64 = readerEvent.target.result;
          const newImage = await this.reduce_image_file_size(image64);
          this.image = newImage;
        };
        reader.readAsDataURL(this.file);
      }
    },
    async reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
      const resized_base64 = await new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL()); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    },
    getAllNotify() {
      const { notificatioAll } = NotificationServices();
      const { notificationAdd } = NotifyStorage();
      notificatioAll(3).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            notificationAdd(item);
          });
          this.getNotify();
        }
      });
    },
    viewNotify(data, index) {
      const { notificatioCheck } = NotificationServices();
      if (data.check === 1) {
        this.checkLoader[index] = true;
        notificatioCheck(data.id).then((resp) => {
          const response = resp.data;
          if (response.code === 200) {
            this.getCountNotify({ count: response.data.count });
            this.$router
              .push({
                path: "order",
                query: { order: data.order.id, check: data.order.check },
              })
              .catch(() => {});
          }
          this.checkLoader[index] = false;
        });
      } else {
        this.$router
          .push({
            path: "order",
            query: { order: data.order.id, check: data.order.check },
          })
          .catch(() => {});
      }
    },
    cancel() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss">
.dialog-notify {
  .row + .row {
    margin-top: 0px;
  }
  .send-message {
    .v-card__title {
      color: var(--color-info-dark);
      font-weight: 600;
      font-size: 1.5rem;
    }

    .v-card__text {
      padding: 16px 16px;
    }
  }

  .btn-send {
    display: flex;
    justify-content: flex-end;

    .v-btn {
      font-size: 14px !important;
    }
    i {
      margin-left: 10px;
    }
  }
}

.noty-admin {
  .card-notify {
    .v-list-item__title {
      font-size: 16px;
      color: var(--color-second);
      font-weight: 600;
    }

    .v-list-item__subtitle {
      font-size: 13px;
      color: #888 !important;
      margin-top: 2px;
    }

    .time {
      font-size: 12px;
      color: #888 !important;
    }
  }

  .btn-send {
    display: flex;
    justify-content: flex-end;
  }

  .card-title {
    padding: 6px 16px !important;
    font-size: 16px !important;
  }

  .card-message {
    padding: 6px 16px !important;
    font-size: 14px !important;
    color: var(--color-second) !important;
    white-space: normal;
    line-height: 20px !important;
  }

  .card-subtitle {
    display: flex;
    justify-content: flex-start;
    color: #959595 !important;
    font-size: 11px;
    // position: absolute;
    // top: 9px;
    // right: 16px;
  }

  .v-list-item__action .icon-warning i {
    color: var(--color-danger) !important;
  }

  .message-error {
    text-align: left;
    font-size: 18px;
    opacity: 0.4;
    margin-top: 30px;
  }
}
</style>
