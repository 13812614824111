var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnDetail)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.detailItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Detalles")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnCheck)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loaderCheck[_vm.index]},on:{"click":function($event){return _vm.checkItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-clipboard-arrow-right ")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.textCheck))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnEdit)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnRecovery)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loaderRecovery[_vm.index]},on:{"click":function($event){return _vm.recoveryItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-lock-open ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Recuperar contraseña")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnDelete)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loaderDelete[_vm.index]},on:{"click":function($event){return _vm.deleteItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnDownPdf)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.colorBtnDownPdf,"loading":_vm.loaderDownPdf},on:{"click":function($event){return _vm.downPdf()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Descargar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnApply)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.colorBtnApply,"loading":_vm.loaderApply},on:{"click":function($event){return _vm.apply()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Aceptar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.btnCancel)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.colorBtnCancel},on:{"click":function($event){return _vm.cancel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Cancelar")])]),(_vm.btnBack)?_c('v-btn',{attrs:{"icon":"","color":_vm.colorBtnBack},on:{"click":function($event){return _vm.back()}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }