const useValidate = {
  required: (v) => !!v || "Campo obligatorio",
  email: (v) => {
    if (!v) return true;
    if (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(v))
      return true;
    return "El correo electrónico es inválido";
  },
  password: (v) =>
    /^(?=.*\d)(?=.*[-!@$%^&*()_+|~=`{}[\]:";'<>?,./])(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(
      v
    ) ||
    "La contraseña debe ser de mínimo 6 caracteres, tener al menos una mayúscula, una minúscula, un valor numérico y un caracter especial",
  phone: (v) => {
    if (!v) return true;
    if (/^([0-9-+ ]{1,20})$/.test(v)) return true;
    return "Formato de número de teléfono no válido";
  },
  dni: (v) => /^([0-9]{1,10})$/.test(v) || "Agregar solo números",
  number: (v) => /^([0-9.,]{1,20})$/.test(v) || "Agregar solo números",
};

export default useValidate;
